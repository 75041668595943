import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CategoryType,
  useMenuClient,
  ViewMenuType,
} from "../client/menuClient";
import { useMenuIdFromURL } from "../hooks/menuIdFromURL";

const defaultMenuData = {
  restaurantName: "",
  currency: "",
  phone: "",
  isRequestForHelpEnabled: false,
  isOrderingEnabled: false,
  categories: [],
  taxPercentage: 0,
  reloadMenuData: () => {},
  isDeliveryEnabled: false,
  isPickupEnabled: false,
  deliveryCharge: 0,
  deliveryNote: "",
  isItemWiseTaxEnabled: false,
  taxLabel: "Tax",
};

const Context = createContext<MenuDataState>({
  menu: undefined,
  reloadMenu: () => {},
});

export const MenuDataProvider = ({
  children,
  authenticated,
}: {
  children: ReactNode;
  authenticated?: boolean;
}) => {
  const menuId = useMenuIdFromURL();
  const { menu, reloadMenu } = useMenuClient(menuId, authenticated);

  return (
    <Context.Provider value={{ menu, reloadMenu }}>{children}</Context.Provider>
  );
};

export const useMenuData: () => MenuDataType = () => {
  const { menu, reloadMenu } = useContext(Context);
  const [menuData, setMenuData] = useState<ViewMenuType>(defaultMenuData);
  useEffect(() => {
    if (menu == undefined) return;

    setMenuData(menu);
  }, [menu, setMenuData]);

  return {
    currency: menuData.currency,
    restaurantName: menuData.restaurantName,
    phone: menuData.phone,
    address: menuData.address,
    isRequestForHelpEnabled: menuData.isRequestForHelpEnabled,
    isOrderingEnabled: menuData.isOrderingEnabled,
    categories: menuData.categories,
    taxPercentage: menuData.taxPercentage,
    reloadMenuData: reloadMenu,
    deviceId: menuData.deviceId,
    isDeliveryEnabled: menuData.isDeliveryEnabled,
    isPickupEnabled: menuData.isPickupEnabled,
    bkashLink: menuData.bkashLink,
    deliveryCharge: menuData.deliveryCharge,
    deliveryNote: menuData.deliveryNote,
    isItemWiseTaxEnabled: menuData.isItemWiseTaxEnabled,
    bin: menuData.bin,
    receiptNote: menuData.receiptNote,
    taxLabel: menuData.taxLabel,
    note: menuData.note,
    isAllTextBold: menuData.isAllTextBold,
    thanksNote: menuData.thanksNote,
  };
};

export type MenuDataType = {
  isOrderingEnabled: boolean;
  restaurantName: string;
  address?: string;
  phone: string;
  reloadMenuData: () => void;
  currency: string;
  categories: CategoryType[];
  deviceId?: string;
  isRequestForHelpEnabled: boolean;
  taxPercentage: number;
  isDeliveryEnabled: boolean;
  isPickupEnabled: boolean;
  bkashLink?: string;
  deliveryCharge: number;
  deliveryNote: string;
  isItemWiseTaxEnabled: boolean;
  bin?: string;
  receiptNote?: string;
  taxLabel: string;
  note?: string;
  isAllTextBold?: boolean;
  thanksNote?: string;
};

export type MenuDataState = {
  menu: ViewMenuType | undefined;
  reloadMenu: () => void;
};
