import styled from "styled-components";

export const HorLine = styled.hr`
  margin-left: 38px;
  margin-right: 38px;
`;

export const Header = styled.h1`
  background-color: rgba(0, 0, 0, 0.83);
  color: white;
  font-size: 30px;
  text-align: center;
  margin-top: -10px;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
`;

export const PhoneHeader = styled.a`
  font-size: 20px;
  font-weight: 700;
  color: #ffffffc9;
  display: block;
`;
