import { AddCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const AddToCartButton = ({
  itemId,
  onIncreaseItemQuantity,
}: {
  onIncreaseItemQuantity: (itemId: number) => void;
  itemId: number;
}) => {
  return (
    <IconButton
      aria-label="add-to-cart-button"
      role="button"
      data-testid="test-id-add-to-cart-button"
      onClick={() => onIncreaseItemQuantity(itemId)}
    >
      <AddCircle
        fontSize="large"
        sx={{
          borderRadius: "50%",
          backgroundColor: "white",
          color: "black",
        }}
      />
    </IconButton>
  );
};
