import { useState } from "react";
import { useCombinedCartAndMenuData } from "./combinedCartAndMenuData";
import { Cart } from "./Cart";
import { Badge, Button, Dialog, IconButton, Typography } from "@mui/material";
import { Cancel, ShoppingCart } from "@mui/icons-material";
import { TopLevelHeaderForMenu } from "../design-system/Header";
import { OrderType } from "../types/OrderType";
import { useMenuIdFromURL } from "../hooks/menuIdFromURL";
import { useHistory } from "react-router-dom";
import { LAST_ORDER_ID_KEY, LAST_ORDER_MENU_ID } from "./LAST_ORDER_KEYS";

export const CartPopUp = () => {
  const [open, setOpen] = useState(false);
  const { numberOfItemsInCart, clearCart } = useCombinedCartAndMenuData();

  const menuId = useMenuIdFromURL();
  const history = useHistory();

  const confirmOrderToCustomer = (order: OrderType) => {
    setOpen(false);
    clearCart();
    localStorage.setItem(LAST_ORDER_ID_KEY, order.id.toString());
    localStorage.setItem(LAST_ORDER_MENU_ID, menuId);

    history.push(`/menu/${menuId}/order/${order.id}`, order);
  };

  return (
    <>
      <Dialog open={open} fullScreen>
        <IconButton
          onClick={() => setOpen(false)}
          data-testid="cart-pop-up-close-button"
          sx={{ position: "absolute", right: 0, top: 0, zIndex: 100 }}
        >
          <Cancel fontSize="large" />
        </IconButton>

        {numberOfItemsInCart == 0 ? (
          <TopLevelHeaderForMenu data-testid="test-id-cart-title">
            No items added
          </TopLevelHeaderForMenu>
        ) : (
          <Cart onOrderPlaced={confirmOrderToCustomer} />
        )}
      </Dialog>

      <Button
        aria-label="my-list-button"
        role="button"
        data-testid="test-id-my-cart-pop-up-button"
        onClick={() => setOpen(true)}
        sx={{
          color: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Badge
          badgeContent={numberOfItemsInCart}
          color="secondary"
          aria-label="number-of-items-in-cart"
          role="text"
          data-testid="test-id-number-of-items-in-cart"
        >
          <ShoppingCart color="inherit" fontSize="large" />
        </Badge>

        <Typography textTransform={"none"}> Cart </Typography>
      </Button>
    </>
  );
};
